.t-wrapper {
  padding: 0 3rem 0 3rem;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 7rem;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.t-heading {
  align-self: start;
}
.t-heading > * {
  font-size: 2rem;
  font-weight: bold;
}

.t-heading span:nth-of-type(2) {
  color: var(--orange);
}

.t-blur1 {
  left: 38rem;
  top: 16rem;
}

.t-blur2 {
  top: 9rem;
  left: 0;
}

.testimonials {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding: 2rem;
  width: 30rem;
  height: 12rem;

  /* styles same as work.css */
  background: rgba(255, 255, 255, 0.26); 
  border: 7px solid var(--blueCard);
  box-shadow: var(--boxShadow);
  border-radius: 20px;
}

.testimonials > img {
  width: 6rem;
  height: 6rem;
  border-radius: 100%;
}

.testimonials > span {
  color: var(--gray);
  font-size: 15px;
}

.testimonials>:nth-child(3){
  font-weight: bold;
  color: black;
  font-size: 20px;
}

/* slider */
.t-wrapper .swiper {
  width: 100%;
  height: 70%;
}

.t-wrapper .swiper-slide {
  display: flex;
  align-items: center;
  justify-content: center;
}

.t-wrapper .swiper-pagination-bullet-active {
  background: var(--orange);
}

@media screen and (max-width: 480px){
  .t-wrapper{
      padding: 0;
  }
  .testimonial>img{
      width: 70px;
      height: 70px;
  }
}